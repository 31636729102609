import React, { useCallback, useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import SiteNavigation from '../SiteNavigation';
import SocialIcons from '../../SocialIcons';

const mobileMenuVar = {
	hidden: {
		width: 0,
		opacity: 0,
		transition: { duration: 0.7, ease: 'anticipate' }
	},
	visible: {
		width: '100%',
		opacity: 1,
		transition: { opacity: { duration: 0.1 }, duration: 0.6, type: 'spring', ease: 'linear' }
	}
};

const MobileMenu = ( { close, open, navigation } ) => {
	useLayoutEffect( () => {
		const listener = () => {
			if ( open ) {
				close();
			}
		};

		window.addEventListener( 'resize', listener );

		return () => {
			window.removeEventListener( 'resize', listener );
		};
	}, [ close, open ] );

	const handleLinkClick = useCallback( e => {
		e.preventDefault();
		e.persist();
		close();
		setTimeout( () => {
			navigate( e.target.href );
		}, 200 );
	}, [ close ] );

	return (
		<>
			<motion.div
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.1, ease: 'linear' } }}
				exit={{ opacity: 0 }}
				className="dimmer"
				onClick={ close }
			/>
			<motion.div
				variants={mobileMenuVar}
				initial="hidden"
				animate="visible"
				exit="hidden"
				className="mobileMenu"
			>
				<SiteNavigation
					onLinkClick={ handleLinkClick }
					disabled={ !open }
					navigation={ navigation }
				/>
				<SocialIcons className="mobileMenuIcons" />
			</motion.div>
		</>
	);
};

MobileMenu.propTypes = {
	close: PropTypes.func,
	open: PropTypes.bool,
	navigation: PropTypes.array
};

export default MobileMenu;
