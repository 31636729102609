import React from 'react';
import PropTypes from 'prop-types';

/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
   import { faFacebookF, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons'; */

// import * as styles from './social-icons.module.css';

const SocialIcons = ( { className } ) => {
	return (
		<div className={ `smIcons ${ className }` }>
			<a href="https://www.instagram.com/kinetic.iq/?hl=en" target="_blank" rel="noopener noreferrer">
				<svg id="ig_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.7 142.3"><g id="katman_1"><polygon className="svg_poli" points="0 35.6 0 106.7 61.8 142.3 123.7 106.7 123.7 35.6 61.8 0 0 35.6"/><path className="svg_icn" d="m81.9,42.7h-40.1c-4.6,0-8.4,3.7-8.4,8.4v40.3c0,4.6,3.7,8.4,8.4,8.4h40.3c4.6,0,8.4-3.7,8.4-8.4v-40.3c-.2-4.7-3.9-8.4-8.6-8.4Zm-19.9,43.4c-8.2,0-14.9-6.7-14.9-14.9s6.7-14.9,14.9-14.9,14.9,6.7,14.9,14.9c0,8.2-6.7,14.9-14.9,14.9Zm16.6-26.8c-2.7,0-5-2.3-5-5s2.3-5,5-5,5,2.3,5,5-2.3,5-5,5Z"/><path className="svg_icn" d="m78.6,52.6c-.9,0-1.7.7-1.7,1.7s.7,1.7,1.7,1.7,1.7-.7,1.7-1.7c0-.9-.8-1.7-1.7-1.7Z"/><path className="svg_icn" d="m62,59.3c-6.4,0-11.7,5.3-11.7,11.7s5.3,11.7,11.7,11.7,11.7-5.3,11.7-11.7-5.3-11.7-11.7-11.7Z"/></g></svg>
			</a>
			<a href="https://www.facebook.com/kineticiq/" target="_blank" rel="noopener noreferrer">
				<svg id="fb_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.8 142.3"><g id="Layer_1-2"><polygon className="svg_poli" points="0 35.6 0 106.7 61.9 142.3 123.8 106.7 123.8 35.6 61.9 0 0 35.6"/><path className="svg_icn" d="m54.5,54.7v8.6h-6.2v10.5h6.2v28.7h12.6v-28.7h8.5s.8-5,1.2-10.5h-9.6v-7.1c0-1.1,1.4-2.5,2.7-2.5h6.9v-10.9h-9.4c-13.2-.1-12.9,10.4-12.9,11.9"/></g></svg>
			</a>
			<a href="https://www.linkedin.com/company/kinetic-iq/" target="_blank" rel="noopener noreferrer">
				<svg id="li_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.8 142.3"><g id="katman_1"><polygon className="svg_poli" points="0 35.6 0 106.7 61.9 142.3 123.8 106.7 123.8 35.6 61.9 0 0 35.6"/><path className="svg_icn" d="m42.4,43.3c0,3.8-3.1,7-6.8,7s-6.8-3.1-6.8-7,3.1-7,6.8-7,6.8,3.1,6.8,7Zm0,12.5h-13.9v44.5h13.9v-44.5Zm22.2,0h-13.8v44.5h13.8v-23.5c0-12.9,16.8-14.1,16.8,0v23.3h13.8v-28.1c0-21.9-24.7-21.1-30.6-10.4,0,.2,0-5.8,0-5.8Z"/></g></svg>
			</a>
		</div>
	);
};

SocialIcons.propTypes = { className: PropTypes.string };

export default SocialIcons;
