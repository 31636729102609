import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { PopupButton } from '@typeform/embed-react';
import SocialIcons from '../SocialIcons';

import SemiCircle from './SemiCircle';

// import useMediaQuery from '../../hooks/useMediaQuery';
import bg from '../../images/footer.svg';
import { toSlug, toTitle } from '../../utils/formatters';

const Footer = ( { logo, companyInfo, navigation } ) => {
	// const matches = useMediaQuery( '(min-width: 768px)' );

	return (
		<div id="form" className="siteFooter">
			<div className="cta" style={{ backgroundImage: `url(${ bg })` }}>
				<div className="tf_cntrl">
					<h1>
						<span>We would love to get to know you!</span>
					</h1>
					<p>
						<span>Your information will only be used to respond to your inquiry</span>
					</p>
				</div>
				<PopupButton id="QNqe29qi" style={{ fontSize: 20 }} className="tf_button">
					Start Now
				</PopupButton>
				<div>
					<span>Takes 2 minutes</span>
				</div>
			</div>
			<footer>
				<SemiCircle className="semiCircle" />
				<div className="wrapper">
					<div className="column companyInfo">
						<Link to="#" className="logoWrapper">
							<img
								alt="KINETIC IQ® Logo"
								className="footer_logo"
								src={ logo }
							/>
						</Link>
						<ul className="companyInfoList">
							<li>{ companyInfo.address }</li>
							<li>{ companyInfo.address2 }</li>
							<li>{ companyInfo.csz }</li>
						</ul>
						<ul className="companyContact">
							<li>{ companyInfo.phone }</li>
							<li>{ companyInfo.email }</li>
						</ul>
					</div>
					{/* change className to footerSiteNavigation */}
					<div className="column footerSiteNavigation">
						<div>
							<p className="overline" >Information</p>
							<ul>
								<li>
									<Link to="/">Home</Link>
								</li>
								{ navigation.map( ( pg, i ) => (
									<li key={ i }>
										<Link to={ toSlug( pg.page ) }>{ toTitle( pg.page ) }</Link>
										{/* {pg.associatedPages && (
											<ul>
												{pg.associatedPages.map( ( ap, i ) => (
													<li key={ i }>
														<Link to={ toSlug( ap ) }>{ toTitle( ap ) }</Link>
													</li>
												) )}
											</ul>
										)} */}
									</li>
								) )}
								<li>
									<Link to="/contact">Contact</Link>
								</li>
								{/* <li>
									<Link to="/terms-and-conditions">
										Terms of Use
									</Link>
								</li>
								<li>
									<Link to="/privacy">
										Privacy
									</Link>
								</li> */}
							</ul>
						</div>
						<div>
							<p className="overline" >Resources</p>
							<ul>
								<li>
									<Link to="/terms-and-conditions">
										Terms of Use
									</Link></li>
								<li>
									<Link to="/privacy">
										Privacy
									</Link></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="content-wrapper bottomBar">
					<SocialIcons className="footerIcons" />
					<div className="copyright">
					Copyright &copy;&nbsp;{ new Date()
							.getFullYear() }&nbsp;&nbsp;&middot;&nbsp;&nbsp;{ companyInfo.companyName }
					</div>
				</div>
			</footer>
		</div>
	);
};

Footer.propTypes = {
	logo: PropTypes.string,
	companyInfo: PropTypes.object,
	navigation: PropTypes.array
};

export default Footer;
