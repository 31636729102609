import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { toTitle } from '../utils/formatters';

const SEO = ( { description, image, lang, title, url } ) => {
	const { site } = useStaticQuery( graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image {
              url
              alt
						}
						siteUrl
          }
        }
      }
    ` );

	const metaDescription = description || site.siteMetadata.description;
	const metaImage = image || site.siteMetadata.image;
	const metaUrl = url || site.siteMetadata.siteUrl;
	const formattedTitle = useMemo( () => `KINETIC IQ${ title ? ` | ${ toTitle( title ) }` : '' }`, [ title ] );

	return (
		<Helmet htmlAttributes={ { lang } }>
			<meta charSet="utf-8" />
			<title>{formattedTitle}</title>
			<meta name="description" content="Empowering Transformation Through Innovative Software Solutions | Kinetic IQ - Your Trusted Partner for Custom Software Development, Data Analytics, and Technology Solutions."/>,
			<meta name="keywords" content="Custom Software Development, PEO Solutions, Logistics EDI Automation, Public Health Solutions, Financial Software, Software Integration, Government Solutions, Workflow Automation, Data Analytics, Technology Solutions, Healthcare Technology, Program Management, Interoperability Solutions, Efficacy Tracking, Data Collection Systems, Industry-specific Software, Technology Innovation"/>,
			<meta name="author" content="Kinetic IQ Team"/>
			<meta property="og:type" content="website" />
			<meta property="og:title" content={formattedTitle} />
			<meta property="og:url" content={ metaUrl } />
			<meta property="og:image" content={ metaImage.url } />
		</Helmet>
	);
};

SEO.defaultProps = {
	lang: 'en',
	description: '',
	image: null,
	url: ''
};

SEO.propTypes = {
	description: PropTypes.string,
	image: PropTypes.shape( {
		url: PropTypes.string,
		alt: PropTypes.string
	} ),
	lang: PropTypes.string,
	title: PropTypes.string,
	url: PropTypes.string
};

export default SEO;
